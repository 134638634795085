import {
  workerRequest,
  analysisRequest,
  // workerEduRequest,
  jobTitleRequest
} from '../../../api'

let data = {
  get: null,
  getMyOrgContainChild: null,
  getMyOrg: null
}

export const loadDetail = async (vm) => {
  let datas = []
  if (vm.$authFunsProxy.get) {
    if (data.get === null) {
      datas = await workerRequest.get()
      data.get = datas
    } else {
      return Promise.resolve(data.get)
    }
  } else if (vm.$authFunsProxy.getMyOrgContainChild) {
    if (data.getMyOrgContainChild === null) {
      datas = await workerRequest.getMyOrgContainChild()
      data.getMyOrgContainChild = datas
    } else {
      return Promise.resolve(data.getMyOrgContainChild)
    }
  } else if (this.$authFunsProxy.getMyOrg) {
    if (data.getMyOrg === null) {
      datas = await workerRequest.getMyOrg()
      data.getMyOrg = datas
    } else {
      return Promise.resolve(data.getMyOrg)
    }
  }

  let wjt = await analysisRequest.certificateLevel()
  // let eduDatas = await workerEduRequest.get()
  // let eduMap = {}
  // eduDatas.filter(v => v.type && v.type.includes('最高学历')).forEach(v => eduMap[v.workerId] = eduMap[v.workerId] ? eduMap[v.workerId].concat(v) : [v])
  let jobTitleData = await jobTitleRequest.getCurrent()
  let jobTitleMap = {}
  jobTitleData.forEach(v => {
    if (jobTitleMap[v.workerId]) {
      if (!jobTitleMap[v.workerId].includes(v.jobTitleType)) {
        jobTitleMap[v.workerId].push(v.jobTitleType)
      }
    } else {
      jobTitleMap[v.workerId] = [v.jobTitleType]
    }
  })
  // 离职的不统计
  datas = datas.filter(v => !v.discipline)
  datas.forEach((v) => {
    // v.zgEducation = eduMap[v.id] && eduMap[v.id].length > 0 ? eduMap[v.id][0].edu : null
    let lWjt = wjt.find(v1 => v1.id === v.id)
    v.jobLevel = lWjt ? lWjt.level : null
    let newData = {}
    v.userOrgRoleList.filter(v1 => v1.roleId).forEach(v1 => {
      newData[v1.roleId] = v1.roleName
    })
    v.jobTitleTypeList = jobTitleMap[v.id] || []
    v.jobTitleTypeText = v.jobTitleTypeList.join(',')
    v.orgRoleLabel = Object.keys(newData).map(key => newData[key]).join(',')
    v.roleIds = Object.keys(newData)
  })
  if (vm.$authFunsProxy.get) {
    data.get = datas
  } else if (vm.$authFunsProxy.getMyOrgContainChild) {
    data.getMyOrgContainChild = datas
  } else if (this.$authFunsProxy.getMyOrg) {
    data.getMyOrg = datas
  }
  return datas
}

export const detailFilter = (item, parm) => {
  let keys = Object.keys(parm)
  let pass = true
  let i = 0
  while (pass && i < keys.length) {
    let key = keys[i]
    if (parm[key]) {
      if (parm[key] === '_null') {
        pass = !item[key] && true
      } else {
        pass = item[key] === parm[key]
      }
    }
    i += 1
  }
  return pass
}

export const getCountData = (dataList, typeList, key, nullName) => {
  let dataMap = {}
  dataList.forEach(v => {
    if (v[key]) {
      dataMap[v[key]] = (dataMap[v[key]] || 0) + 1
    } else {
      dataMap['_null'] = (dataMap['_null'] || 0) + 1
    }
  })
  let keys = Object.keys(dataMap)
  let data = typeList.filter(v => keys.includes(v.name)).map(v => {
    return {
      key: v.name,
      name: v.name,
      value: dataMap[v.name]
    }
  })
  if (dataMap['_null']) {
    data.push({
      key: '_null',
      name: nullName,
      value: dataMap['_null']
    })
  }
  return data
}